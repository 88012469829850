import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-external-url-viewer',
  templateUrl: './external-url-viewer.component.html',
  styleUrls: ['./external-url-viewer.component.scss']
})
export class ExternalUrlViewerComponent {
  @Input() url = '';
  @Output() closeClick = new EventEmitter();

  constructor() {}

  onClose(): void {
    this.closeClick.emit();
  }
}
