import { Component, OnInit } from '@angular/core';
import { DateQuestion, InputType } from './date-question.model';
import { Materialize } from '../../../../materialize';
import * as moment from 'moment';
import { CustomValidators } from '../../../../common/validators/custom-validators.helper';
import { AbstractControlComponent } from '../abstract-control/abstract-control.component';
import { FormControl } from '@angular/forms';

declare const M: Materialize;

@Component({
  selector: 'app-date-control',
  templateUrl: './date-control.component.html',
  styleUrls: ['./date-control.component.scss']
})
export class DateControlComponent
  extends AbstractControlComponent<string, DateQuestion, FormControl>
  implements OnInit {
  private picker: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    // adjust init value for timezone if needed
    let currentValue;
    if (this.data !== undefined && this.data !== null) {
      currentValue = moment(this.data);
      const utcOffset = currentValue.utcOffset();
      if (utcOffset < 0) {
        currentValue.utc().add(-utcOffset, 'minute');
      }
    }

    const datePickers = document.querySelectorAll('.datepicker');
    this.picker = datePickers.item(0);
    M.Datepicker.init(datePickers, {
      minDate:
        this.question.control.input_type === InputType.Future
          ? new Date()
          : null,
      maxDate:
        this.question.control.input_type === InputType.Past ? new Date() : null,
      yearRange: 100,
      showClearBtn: true,
      setDefaultDate: currentValue !== undefined,
      defaultDate: currentValue !== undefined ? currentValue.toDate() : null,
      onSelect: (date) => {
        this.formControl.setValue(moment(date).format('YYYY-MM-DD 00:00:00'));
        this.formControl.markAsTouched();
        this.emitOutput();
        console.log(this.data);
      }
    });

    this.setupSimpleFormControl(
      currentValue !== undefined ? currentValue.toDate() : null
    );
  }

  getValidators(): any[] {
    const validators = super.getValidators();
    validators.push(CustomValidators.validDate());
    switch (this.question.control.input_type) {
      case InputType.Past:
        validators.push(CustomValidators.validPastDate());
        break;
      case InputType.Future:
        validators.push(CustomValidators.validFutureDate());
        break;
    }
    return validators;
  }

  dateChanged(): void {
    if (this.picker.value === null || this.picker.value.length === 0) {
      this.formControl.setValue(null);
      this.formControl.markAsTouched();
      this.emitOutput();
    }
  }
}
