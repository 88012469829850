export enum QuestionType {
  INPUT = 'input',
  TEXT = 'text',
  TERMS = 'terms',
  RADIO = 'radio',
  DROPDOWN = 'dropdown',
  PHOTO = 'photo',
  STATES = 'states',
  DATE = 'date',
  MULTIRADIO = 'multiradio',
  SIGNATURE = 'signature',
  BLOCK_SIGNATURE = 'block_signature',
  LINK = 'link',
  MULTI_SELECT = 'multi_select',
  SINGLE_SELECT = 'single_select',
  UNKNOWN = 'unknown'
}
