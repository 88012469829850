<button mat-flat-button
        [disabled]="disabled"
        (click)="emitChange(!value)">
  <ng-container *ngTemplateOutlet="value ? whenHideTrue : whenHideFalse">
  </ng-container>
</button>

<ng-template #whenHideTrue>
  <mat-icon>visibility</mat-icon>
  {{ 'FORMS.LITE_EDITOR.SHOW' | translate }}
</ng-template>

<ng-template #whenHideFalse>
  <mat-icon>visibility_off</mat-icon>
  {{ 'FORMS.LITE_EDITOR.HIDE' | translate }}
</ng-template>
