import { LiteEditorSectionGroup } from './lite-editor-section.group';

export interface FormActionRules {
  change_page_name?: FormActionRule[];
  add_question?: FormActionRule[];
  toggle_page_visibility?: FormActionRule[];
}

export interface FormActionRule {
  hint: string;
  type: FormActionRuleType;
  condition?: FormActionRuleCondition;
  customResolverFn?: (
    formPages: LiteEditorSectionGroup[]
  ) => LiteEditorSectionGroup[];
}

export enum FormActionRuleType {
  NO_QUESTION_ON_PAGE = 'no_question_on_page',
  NO_MORE_THAN_ONE_QUESTION_ON_FORM = 'no_more_than_one_question_on_form',
  CUSTOM = 'custom'
}

export interface FormActionRuleCondition {
  property: string;
  value: string;
}
