export interface FormSpecVersion {
  created_at: Date;
  id: number;
  resource: FormSpecVersionResource;
  type: string;
  label?: string;
}

export enum FormSpecVersionResource {
  DRAFT = 'DraftFormSpec',
  VERSION = 'FormSpec',
  DEFAULT = 'DefaultFormSpec'
}
