import { NgModule } from '@angular/core';
import { HideToggleControlComponent } from './hide-toggle-control.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, TranslateModule],
  exports: [HideToggleControlComponent],
  declarations: [HideToggleControlComponent]
})
export class HideToggleControlModule {}
