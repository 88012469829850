export enum LoadingState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED'
}

export interface ErrorState {
  message: string;
  code: number;
}

export type CallState = LoadingState | ErrorState;
