import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Materialize } from './materialize';
import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';

declare const M: Materialize;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private readonly mediaMatcher: MediaMatcher,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.updateFavicon();
    M.AutoInit();
  }

  private updateFavicon(): void {
    const darkThemeMatcher = this.mediaMatcher.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    const lightSchemeIcon = this.document.querySelector(
      'link#favicon-light'
    ) as HTMLElement;
    const darkSchemeIcon = this.document.querySelector(
      'link#favicon-dark'
    ) as HTMLElement;

    if (darkThemeMatcher.matches) {
      this.renderer.removeChild(this.document.head, lightSchemeIcon);
      this.renderer.appendChild(this.document.head, darkSchemeIcon);
    } else {
      this.renderer.removeChild(this.document.head, darkSchemeIcon);
      this.renderer.appendChild(this.document.head, lightSchemeIcon);
    }
  }
}
