<h2 *ngIf="data?.title"
    mat-dialog-title>
  <mat-icon *ngIf="data.iconName">{{data.iconName}}</mat-icon>
  {{ data?.title }}
</h2>
<div *ngIf="data?.description"
     class="confirm__content">
  {{ data?.description }}
</div>
<mat-dialog-actions align="end">
  <button *ngIf="data?.cancelBtn"
          mat-flat-button
          mat-dialog-close>
    {{ data?.cancelBtn }}
  </button>
  <button mat-flat-button
          color="primary"
          class="primary-variant"
          [mat-dialog-close]="true"
          cdkFocusInitial>
    {{ data?.confirmBtn }}
  </button>
</mat-dialog-actions>
