// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  envName: 'dev',
  production: false,
  serverUrl: 'https://dev.modento.io',
  apiUrl: 'https://dev.modento.io/api/v1',
  clientId: 25,
  clientSecret: 'hRT1t0nIjKRWbgzPxa53gftyyvJLuNJzaMSgosjH',
  formValidation: true,
  pusher: {
    APP_KEY: '71ff96d3adf929ba3fec',
    logToConsole: true,
    options: {
      cluster: 'us3',
      forceTLS: true
    }
  },
  sentry: {
    DSN: 'https://02660bf555104da08a69a7c3d05b8b1a@sentry.io/1827605'
  },
  development: {
    forceFormEditorInPreview: false,
    debugLiteEditor: false
  },
  postMessage: {
    allowedPatientPortalUrls: [
      'http://localhost:4204',
      'https://dev-app.modento.io'
    ]
  },
  wisetack: {
    widerWisetackPaymentOption: true
  },
  clearent: {
    sdk: 'https://gateway-sb.clearent.net/js-sdk/js/clearent-host.js',
    baseUrl: 'https://gateway-sb.clearent.net'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
